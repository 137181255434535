import { PathFragment } from '@dustin-web/microsite-components/src/breadcrumbs'
import { useUserSettings, useTranslations, useSiteContext } from '@dustin-web/microsite-components/src/hooks'
import { EditorialPageFragment } from 'src/graphql/generated/graphql-types'

const getCommonProperties = (
  page: EditorialPageFragment,
  cultureCode: string
) => {
  const name = page.title
  const description = page.metaDescription
  const inLanguage = cultureCode

  const commonProperties = {
    name,
    description,
    inLanguage
  }

  return commonProperties
}

const getImageObject = (imageUrl?: string) => {
  if (!imageUrl) {
    return
  }

  return {
    '@type': 'ImageObject',
    url: imageUrl,
  }
}

type BreadcumbProps = {
  path: PathFragment[]
}

type BreadcrumbSchemaItem = {
  '@type': string
  position: number
  name: string
  item?: string
}

export const BreadcumbSchema = ({path}:BreadcumbProps) => {
  const { translate } = useTranslations()
  const {  siteUrl } = useSiteContext()
  const items = path.map((item: PathFragment, i: number) => {
    const listItem: BreadcrumbSchemaItem = {
      '@type': 'ListItem',
      position: i + 1,
      name: i === 0 ? translate('Homepage') : item.displayName,
    }

    if (i === 0) {
      listItem.item = siteUrl
    } else if (i !== path.length - 1) {
      listItem.item = `${siteUrl}${item.url}`
    }

    return listItem
  });

  return (
    <div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'BreadcrumbList',
            itemListElement: items,
          }),
        }}
      />
    </div>
  )
}

type EditorialPageProps = {
  page: EditorialPageFragment
}

export const EditorialPageSchema = ({ page }: EditorialPageProps) => {
  const { cultureCode } = useUserSettings()
  const primaryImageOfPage = getImageObject(page.ogImage?.url)
  const commonProperties = getCommonProperties(page, cultureCode)
  const data = { ...commonProperties, primaryImageOfPage }

  return (
    <div>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'WebPage',
            ...data,
          }),
        }}
      />
    </div>
  )
}