'use client'

import React from 'react'
import dynamic from 'next/dynamic'
import {
  EditorialPageContainerType,
  EditorialPageFragment,
} from 'src/graphql/generated/graphql-types'
import { useWebToggles } from '@dustin-web/microsite-components/src/hooks'
import { EditorialPageSchema } from '../schemas/web-page-schema'

const BlockList = dynamic(() => import('../blocks').then(mod => mod.BlockList))
const MacroContent = dynamic(() => import('../macros/macro-content').then(mod => mod.MacroContent))
const Breadcrumbs = dynamic(() =>
  import('@dustin-web/microsite-components/src/breadcrumbs').then(mod => mod.Breadcrumbs)
)
const BreadcumbSchema = dynamic(() =>
  import('../schemas/web-page-schema').then(mod => mod.BreadcumbSchema)
)

const Editorial = (props: { page: EditorialPageFragment }) => {
  const { isToggleEnabled } = useWebToggles()
  const page = props.page

  const breadcrumbs = page.breadcrumbs.map(breadcrumb => ({
    displayName: breadcrumb.displayName,
    url: breadcrumb.url,
    clientNavigationRoute: breadcrumb.url,
  }))

  let pageContent

  if (props.page.contentBlocks?.length > 0) {
    pageContent = <BlockList blocks={props.page.contentBlocks} firstLevel={true} />
  } else if (page.container === EditorialPageContainerType.Styledust) {
    pageContent = <MacroContent content={page.body} />
  } else if (page.container === EditorialPageContainerType.Fluid) {
    pageContent = (
      <div data-gasource="Editorial">
        <MacroContent content={page.body} />
      </div>
    )
  } else {
    pageContent = (
      <div className="container">
        <MacroContent content={page.body} />
      </div>
    )
  }

  const includeBreadcrumbs = page.body.replace(/<\!--.*?-->/g, '').includes('c-breadcrumbs')

  return (
    <>
      {!includeBreadcrumbs && isToggleEnabled('enable-dynamic-breadcrumbs-on-editorial-pages') ? (
        <div className="container">
          <Breadcrumbs path={breadcrumbs} />
        </div>
      ) : (
        <BreadcumbSchema path={breadcrumbs} />
      )}
      <EditorialPageSchema page={page} />
      {pageContent}
    </>
  )
}

export default Editorial
